import { css } from "styled-components"

export const testimonials = css`

  section {

    background-color: #cabaac;
    background-image: url("/background_image.jpg");
    background-size: cover;
    background-position-x: 55%;

    .testimonials-container {
      padding-top: 190px;
      padding-bottom: 110px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      max-width: 970px;
      min-height: 600px;

      .nav-arrow {
        position: relative;

        &__icon {

          &--wrapper {
            padding: 30px;
            box-sizing: border-box;
            position: absolute;
            top: calc(50% + 45px);
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            cursor: pointer;
            transition: box-shadow 500ms, border 300ms;

            &:hover {
              .nav-arrow__icon--left {
                transform: translateX(-3px);
              }
              .nav-arrow__icon--right {
                transform: translateX(3px);
              }
            }

            &.left-wrapper:hover {
              box-shadow: 1px 1px 3px #0000005c;
            }

            &.right-wrapper:hover {
              box-shadow: -1px 1px 3px #0000005c;
            }

            &.left-wrapper:active {
              box-shadow: 1px 1px 1px #00000024;
            }
            &.right-wrapper:active {
              box-shadow: -1px 1px 1px #00000024;
            }
          }

          transition: all 0.2s;
          background-size: cover;
          
          width: 12px;
          height: 24px;

          &--left {
            background-image: url("/arrow_left.png");
            &:hover {
              /* transform: translateX(-3px) */
            }
          }

          &--right {
            background-image: url("/arrow_right.png");
            &:hover {
              /* transform: translateX(3px) */
            }
          }

        }
      }

      .testimonials {

        @media (max-width: 768px) {
          margin-left: 50px;
          margin-right: 50px;
          padding-top: 50px;
          min-height: 550px;
        }

        @media (min-width: 768px) {
          position: absolute;
          left: 50%;
          top: calc(50% + 30px);
          transform: translateX(-50%) translateY(-50%);
        }

        opacity: 1;
        transition: opacity 500ms, transform 300ms;
        transform-origin: center center;

        &.changing-text-animation {
          opacity: 0.3;
          transform: scale(0.97); 

          @media (min-width: 768px) {
            transform: translateX(-50%) translateY(-50%) scale(0.97);
          }
        }

        .testimonial {

          text-align: center;
          display: none;

          &.active {
            display: block;
          }

          &__icon {
            /* @temp */
            width: 42px;
            height: 50px;
            background-image: url("/ico_logo.png");

            margin: 0 auto;
            margin-bottom: 30px;

            @media (max-width: 768px) {
              background-color: #e0d5b07a;
              padding: 10px;
              background-repeat: no-repeat;
              border-radius: 5px;
              background-position: 10px;
              box-shadow: 1px 1px 1px #bbbbbb;
            }
          }

          &__title {
            margin-bottom: 20px;
            font-size: 24px;
          }

          &__text {
            max-width: 822px;
            margin: 0 auto;
            margin-bottom: 26px;
            line-height: 26px;
          }

          &__case-study-link {
            display: block;
            margin-bottom: 25px;

            &:hover {
              font-weight: bold;
            }
          }

          &__date {
            span { 
              font-weight: bold;
            }
          }

        } /* end of .testimonial */
        
      } /* end of .testimonials */
      
    } /* end of .testimonials-container */

  } /* end of .section */

`