import { css } from "styled-components"

export const ourProcess = css`

  section {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .container {
    /* In order to contain .background-boxes as abs. pos. */
    position: relative; 
  }

  .background-boxes {

    position: absolute;
    top: -100px;
    left: 0;
    z-index: 1;

    .box {

      position: absolute;
      background-color: #F9F9F9;

      &--1 {
        left: 85px;
        width: 155px;
        height: 150px;
        border-radius: 0 0 0 40px;
      }

      &--2 {
        left: 240px;
        top: 150px;
        width: 100px;
        height: 100px;
      }

    }

  }

  .content {

    .section-title {
      text-align: center;
      position: relative;
      z-index: 2;

      /* @TODO - Will have to go abs. positioned so it can go over the background boxes */
    }

    .processes {

      position: relative;
      z-index: 2;

      .processes-row {
        display: flex;
        justify-content: center;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        &:first-child {
          margin-bottom: 60px;
          
          @media (max-width: 768px) {
            margin-bottom: inherit;
          }
        }

        .process {
          flex-basis: 360px;

          @media (max-width: 768px) {
            flex-basis: auto;
            margin-bottom: 55px;
          }

          &:nth-of-type(2) {
            margin-left: 30px;
            margin-right: 30px;

            @media (max-width: 768px) {
              margin-left: inherit;
              margin-right: inherit;
            }
          }

          &__icon {
            width: 40px;
            height: 40px;
            background-size: cover;
            margin-bottom: 20px;

            &.p-icon {

              &--1 {
                width: 24px;
                height: 37px;
                background-image: url("/ico_client_interview.png");
              }
              &--2 {
                width: 41px;
                height: 37px;
                background-image: url("/ico_payment_opt.png");
              }
              &--3 {
                width: 33px;
                height: 37px;
                background-image: url("/ico_assign.png");
              }
              &--4 {
                width: 49px;
                height: 37px;
                background-image: url("/ico_review_edit_content.png");
              }
              &--5 {
                width: 37px;
                height: 37px;
                background-image: url("/ico_send_content.png");
              }
              &--6 {
                width: 37px;
                height: 37px;
                background-image: url("/ico_Assess_feedback.png");
              }
            }
          }

          &__title {
            line-height: 34px;
            font-size: 24px;
            margin-bottom: 20px;
            max-width: 230px;
          }

          &__description {
            margin-top: 0px;
            line-height: 26px;
          }
        }
      }

    } /* end of .processes */

  } /* end of .content */

`