import { css } from "styled-components"

export const hireUs = css`

  section {

    padding-top: 170px;
    padding-bottom: 200px;

    @media (max-width: 768px) {
      padding-top: 120px;
    }

  }

  .hire-us {

    &__top-part {

      display: flex;
      margin-bottom: 60px;

      .logo {
        width: 170px;
        height: 170px;
        background-image: url(/logo_green.png);
        background-size: cover;
        margin-right: 24px;

        @media (max-width: 768px) {
          background-repeat: no-repeat;
          background-size: contain;
          width: 45px;
          height: 45px;
        }
      }

      .section-title {
        margin: 0;
        line-height: 170px;
        
        @media (max-width: 768px) {
          transform: translate(-4px,5px);
        }
      }

    }

    &__content-part {

      display: flex;
      margin-bottom: 60px;

      @media (max-width: 768px) {
        flex-direction: column;
      }
      
      .text {
        
        font-size: 24px;
        line-height: 34px;
        max-width: 546px;
        margin-bottom: 0;

        &:nth-of-type(2) {
          margin-left: 36px;

          @media (max-width: 768px) {
            margin-left: inherit;
            margin-top: 40px;
          }
        }

      }

    }

  }

`