import { css } from "styled-components"

export const heroHeader = css`
  
  height: 915px;

  @media (max-width: 768px) {
    height: auto;
  }
  
  .content {

    display: flex;

    & > div {
      overflow: hidden;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
    
    &__left-col {

      flex-basis: 58%;
      flex-grow: 1;
      flex-shrink: 0;

      @media (max-width: 1080px) {
        padding-right: 30px;
        box-sizing: border-box;
      }

      @media (max-width: 768px) {
        transform: initial;
        width: 120%;
        margin-left: -7%;
        padding: 0 30px;
        background: linear-gradient(63deg, #48E4B7 325px, white 150px);
        background-position-x: -20px;
        background-repeat: no-repeat;
        box-sizing: border-box;
        padding-top: 50px;
        padding-bottom: 85px;
      }

      @media (max-width: 34px) {
        /*  */
      }

      transform: translateY(75px);

      /*    Marigns and positions.     */
      .heading {

        margin-bottom: 10px;
        max-width: 546px;
        font-size: 60px;

        @media (max-width: 768px) {
          line-height: 1.1;
          margin-bottom: 25px;
          max-width: 90vw;
        }
        @media (max-width: 34px) {
          font-size: 55px;
        }

      }
      .text {
        margin-bottom: 40px;
        max-width: 562px;
        font-size: 24px;

        @media (max-width: 768px) {
          padding-right: 40px;
          box-sizing: border-box;
        }
      }

    }

    &__right-col {

      position: relative;

      flex-basis: 90%;
      flex-shrink: 0;

      transition: background-color 300ms;

      h2, p {
        color: white;
        transition: color 300ms;
      }

      &--slider-state {

        &--0 {

          background-color: #FCEDBD;

          h2, p {
            color: black;
          }

          .hero-content-icon {
            background-image: url('/ico_content_writing_2@2x.png');
          }

        }

        &--1 {

          background-color: #BF469F;

          h2 {
            color: white;
          }

          p,
          button, span {
            color: #e0e0e0;
          }

          .main-cta-btn__circle-icon {
            border-color: #e0e0e0;
          }

          .hero-content-icon {
            background-image: url('/ico_guest_blogging_headerv2b@2x.png');
          }

        }

        &--2 {

          background-color: #1BD4E9;

          .hero-content-icon {
            background-image: url('/ico_smm_header.png');
          }

        }

      }
      
      transform: translateY(-82px);
      height: 650px;
      border-radius: 40px;

      padding-top: 130px;
      padding-left: 118px;
      box-sizing: border-box; /** border-box to prevent padding affecting height */

      @media (max-width: 768px) {
        transform: initial;
        position: relative;
        margin-top: 0;
        border-radius: 0;
        /* background: linear-gradient(10deg, #FCEDBD 600px, #e2e2e2 40px); */
        box-sizing: border-box;
        height: auto;
        width: 120%;
        margin-left: -10%;
        padding: 0 40px;
        padding-top: 120px;
        padding-bottom: 120px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 50px;
          background: linear-gradient(5deg, #FCEDBD 43px, #e2e2e2 10px);
        }
      }

     .hero-content-icon {
        width: 80px;
        height: 50px;
        background-size: contain;
        background-repeat: no-repeat;
        transition: background-image 300ms;
      }
      .heading,
      .text {
        margin-top: 20px;
      }
      .heading {
        font-size: 45px;
      }
      .text {
        font-size: 24px;
        max-width: 488px;
      }
      button {
        margin-top: 50px;
      }

      .hero-slider-nav {
        position: absolute;
        bottom: 71px;
        display: flex;

        @media (max-width: 768px) {
          position: relative;
          bottom: initial;
          margin-top: 30px;
        }
      }
      .slider-nav {
        position: absolute;
        bottom: 15px;

        @media (max-width: 768px) {
          position: relative;
          bottom: initial;
        }
      }

    }

  }
  

`

