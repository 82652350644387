import React, { useState, useEffect } from "react"
import styled from "styled-components"

import PageSection from "../../../layouts/PageSection/PageSection.component"
import { PageComponentPropsI } from "../../../components/layout-components/_/PageComponentTypes"
import { whyChooseUs } from "../css/whyChooseUs.css"
import MainCtaBtn from "../../../components/layout-components/_/buttons/MainCtaButton/MainCtaButton.component"
import SliderNav, { arrowColorType } from "../../../components/layout-components/_/sliders/SliderNav/SliderNav.component"

interface WhyChooseUsPropsI extends PageComponentPropsI {
  //
}

const sliderTexts = [
  {
    title: "We’ve been around the block and we know what works",
    bodyText: "Our team has a decade of experience working together on an amazingly wide range of topics, from SMBs in different industries, retailers and ecommerce sites, all the way to the medical field, IT, and health and fitness."
  },
  {
    title: "We’re always open about our process and pricing",
    bodyText: "There are no hidden fees, no technicalities or gimmicks, what you see is what you get. We’ll make sure that we know exactly what you want and that you know exactly what to expect before the project even starts."
  },
  {
    title: "We offer a great balance of speed and quality",
    bodyText: "We won’t pump-out hundreds of articles a week to drown the net with your keywords, but we also won’t make you wait for your order for weeks. We always set a realistic deadline to ensure that we can provide good content that will help you rank better and grab people’s attention."
  },
  {
    title: "We Have an Army of Satisfied Clients to Vouch for Us",
    bodyText: "With years of dedicated work behind us, we’ve been able to work with some truly amazing people and brands, and we have the reviews to back up our claims."
  }
];


// @TODO - Either VScode or TypeScript complains about "NO overload matches this call", and it didn't syntax highlight the below code.
const SliderProgress = styled.div.attrs<{
    progressPercentage: string;
  }>
  (({progressPercentage, ...rest}) => ({
    progress: progressPercentage ?? "25%"
  }))
  <{progress: string}>`
    :after {
      width: ${props => props.progress} !important;
    }
`;

const WhyChooseUs: React.FC<WhyChooseUsPropsI> = (props) => {

  const [sliderIndex, setSliderIndex] = useState(0);

  const [sliderProgressPercentage, setsliderProgressPercentage] = useState("33%");

  useEffect(() => {

    switch (sliderIndex) {

      case 0: {
        setsliderProgressPercentage("25%");
      } break;

      case 1: {
        setsliderProgressPercentage("50%");
      } break;

      case 2: {
        setsliderProgressPercentage("75%");
      } break;

      case 3: {
        setsliderProgressPercentage("100%");
      } break;

    }

  })

  return (

    // <PageSection extendStyles={`${whyChooseUs} ${props.css ?? props.css}`}> 
    // @TODO - Fix extending styles from the props.
    <PageSection extendStyles={whyChooseUs}>

      <div className="background-boxes background-boxes--1">
        <div className="box box-1"></div>
        <div className="box box-2"></div>
      </div>

      <div className="content">

        <div className="content__left-col">

          <h2 className="section-title">Why choose us?</h2>
          
          <p className="text"><strong>Podroom Creative</strong> is the result of years of hard work in the Digital Marketing industry.<br /><br /> A family of creative individuals with a wide range of interests united by a singular cause – to grow by helping others grow.</p>

          <MainCtaBtn buttonText="About Us" />

        </div>

        <div className="content__right-col">

          <div className="content__right-col__slider-content">

            <h4 className="heading">{sliderTexts[sliderIndex].title}</h4>

            <hr />

            <p className="text">{sliderTexts[sliderIndex].bodyText}</p>

            <SliderProgress 
              className="slider-progress" 
              progressPercentage={sliderProgressPercentage ?? "33%"}
            />

            <SliderNav
              className="slider-nav"
              arrowColorType={arrowColorType.GRAY}
              pageNumberColor="#48E4B7"
              activePageUnderlineColor="#48E4B7"
              totalPages={sliderTexts.length}
              currentPage={sliderIndex + 1}
              onPageClick={page => setSliderIndex(page - 1)}
            />

          </div>{/* end of .content__right-col__slider-content */}
          
        </div>{/* end of .content__right-col */}

      </div>{/* end of .content */}

      <div className="background-boxes background-boxes--2">
        <div className="box"></div>
      </div>
      <div className="background-boxes background-boxes--3">
        <div className="box"></div>
      </div>
      <div className="background-boxes background-boxes--4">
        <div className="box"></div>
      </div>

      <div className="clear"></div>
          
    </PageSection>

  )

}

export default WhyChooseUs;