import React from "react"
import { SliderNavStyled, SliderNavStyledPropsI } from "./css/SliderNavStyled.css";

interface SliderNavPropsI extends SliderNavStyledPropsI {
  className?: string;
  currentPage: number;
  totalPages: number;
  onPageClick: (page: number) => void
}

export { arrowColorType } from "./css/SliderNavStyled.css";

/**
 * Slider navigation only. @TODO - should be refactored to full slider or re-used in slider.
 * @param props - Use exported constants `arrowColorType` to set value for props.arrowColorType
 */
const SliderNav: React.FC<SliderNavPropsI> = (
  {
    totalPages, currentPage, onPageClick,
    ...rest
  }) => {

  const _onPageClick = (page: number) => {

    let _page = page;

    if (page > totalPages) {
      _page = 1;
    }

    if (page < 1) {
      _page = totalPages;
    }

    onPageClick(_page);
    
  }

  return (

    <SliderNavStyled
      {...rest}
      >

        <div className="hero-slider-nav">

          <div 
            className="hero-slider-nav__arrow hero-slider-nav__arrow--left" 
            onClick={() => _onPageClick(currentPage - 1)}
          />

          <div className="hero-slider-nav__numbers-container">

            {Array.from({length: totalPages}).map((_, i) => (
              <span 
                className={`hero-slider-nav__number ${currentPage === (i + 1) ? 'active' : ''}`}
                onClick={() => _onPageClick(i + 1)}
                >
                  {i + 1}
              </span>  
            ))}

          </div>

          <div 
            className="hero-slider-nav__arrow hero-slider-nav__arrow--right" 
            onClick={() => _onPageClick(currentPage + 1)}
          />

        </div>

    </SliderNavStyled>

  )

}

export default SliderNav;