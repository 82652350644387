import styled from "styled-components"
import { sliderNav } from "./sliderNav.css"

export interface SliderNavStyledPropsI {
  arrowColorType?: string; // Use exported constants `arrowColorType` to set value for props.arrowColorType
  pageNumberColor?: string;
  activePageUnderlineColor?: string;
}

export const arrowColorType = {
  YELLOW: "yellow",
  GRAY: "gray",
  PINK: "pink",
  BLUE: "blue",
  GREEN: "green",
  WHITE: "white"
}

const getArrowColorByType = (type: string) => {

  switch (type) {

    case arrowColorType.YELLOW:
      return "#E2D5AA";
      break;

    case arrowColorType.PINK:
      return "#FF00BA";
      break;

    case arrowColorType.GRAY:
      return "#404040";
      break;

    case arrowColorType.BLUE:
      return "#008797";
      break;

    case arrowColorType.GREEN:
      return "#7bb9a7";
      break;

    case arrowColorType.WHITE:
      return "white";
      break;
  
    default:
      return "#404040" // gray
      break;
  }
}

export const SliderNavStyled = styled.div<SliderNavStyledPropsI>`

  ${sliderNav}

  .hero-slider-nav {

    &__number {
      color: ${props => props.pageNumberColor ?? "black"};

      &:after {
        border-bottom-color: ${props => props.activePageUnderlineColor ?? "black"};
      }
    }

    &__arrow {

      &--left {
        border-color: transparent ${props => props.arrowColorType 
          ? getArrowColorByType(props.arrowColorType)
          : "black"
        } transparent transparent;
      }

      &--right {
        border-color: transparent transparent transparent ${props => props.arrowColorType 
          ? getArrowColorByType(props.arrowColorType)
          : "black"
        };
      }

    }

  }

`