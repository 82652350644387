import { css } from "styled-components"

export const ourOffer = css`

  section {
    background-color: #F9F9F9;
    padding-top: 100px;
    padding-bottom: 100px;

    @media (max-width: 768px) {
      padding-bottom: 175px;
    }
  }

  .services-offer {

    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .service-box {
      
      flex-basis: 364px;
      flex-shrink: 0;
      position: relative;

      box-sizing: border-box;
      padding-left: 29px;
      padding-right: 29px;

      background-color: white;
      border: 1px solid #ECECEC;
      border-radius: 12px;

      padding-bottom: 65px;

      &:nth-of-type(2) {
        margin-left: 24px;
        margin-right: 24px;

        @media (max-width: 768px) {
          margin-left: inherit;
          margin-right: inherit;
        }
      }

      @media (max-width: 768px) {
        &:not(:last-child) {
          margin-bottom: 125px;
        }
      }

      .left-box-bookmark {
        position: relative;
        width: 70px; /* 68px original */
        height: 49px; /* 47px original */
        background-color: #F9F9F9;
        position: absolute;
        left: -2px;
        top: -2px;
        border-right: 1px solid #ECECEC;
        border-bottom: 1px solid #ECECEC;

        &__icon {
          position: absolute;
          left: 10px;
          top: 6px;

          &:after {
            content: "";
            position: absolute;
            left: 50px;
            top: -14px;
            width: 25px;
            height: 54px;
            transform: rotate(29deg);
            background-color: #F9F9F9;
            border-right: 1px solid #ECECEC;
          }

          width: 36px;
          height: 25px;

          &.lbb-icon {

            &--1 {
              background-image: url("/ico_content_writing.png");
            }

            &--2 {
              width: 25px;
              background-image: url("/ico_guest_blogging.png");
            }

            &--3 {
              width: 30px;
              height: 27px;
              background-image: url("/ico_smm.png");
            }

          }
        }
      }

      .heading {
        margin-top: 76px;
        font-size: 32px;
      }

      .service-box-icon {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        border-width: 3px;
        border-style: solid;
        
        margin: 20px auto;

        &.orange {
          border-color: #FFCF37;
        }
        &.maroon {
          border-color: #AA007C;
        }
        &.cyan {
          border-color: #1BD4E9;
        }
      }

      p {
        margin-bottom: 35px;
        line-height: 26px;
      }

      .main-cta-btn {
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
      }

    }

  }



`