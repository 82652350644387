import React, { useEffect, useState } from "react"
// import TextTransition, { presets } from "react-text-transition";

import LogoWithNavigation from "../../../_/LogoWithNav/LogoWithNav.component"
import { HeroHeaderStyled } from "./css/HeroHeaderStyled.css"
import MainCtaButton from "../../../../_/buttons/MainCtaButton/MainCtaButton.component"
import SliderNav, { arrowColorType } from "../../../../_/sliders/SliderNav/SliderNav.component"

interface HeroHeaderProps {
  data?: {
    heading?: string,
    text?: string,
    buttonText?: string
  }
}

const sliderTexts = [
  {
    title: "Creative Writing",
    subText: "Anything from blog posts to product pages made to fit your target audience, niche, and preferred style."
  },
  {
    title: "Guest Blogging",
    subText: "Informative and engaging articles that tick all the SEO boxes and tickle the reader’s imagination."
  },
  {
    title: "SMM",
    subText: "Let us share your content, get people excited about clicking and commenting, and create some hype about your brand."
  }
]

const HeroHeader: React.FC<HeroHeaderProps> = ({data}) => {
  
  const [sliderIndex, setSliderIndex] = useState(0);

  const [sliderNavArrowColor, setSliderNavArrowColor] = useState(arrowColorType.YELLOW);
 
  useEffect(() => {
    const intervalId = setInterval(() => {
        let newIndex = sliderIndex + 1;
        if (newIndex > 2) {
          newIndex = 0;
        }
        setSliderIndex(newIndex);
      },
      7000 // every 7 seconds
    );
    return () => clearInterval(intervalId);
  });

  useEffect(() => {

    switch (sliderIndex) {

      case 0: {
        setSliderNavArrowColor(arrowColorType.YELLOW);
      } break;

      case 1: {
        setSliderNavArrowColor(arrowColorType.GREEN);
      } break;

      case 2: {
        setSliderNavArrowColor(arrowColorType.BLUE);
      } break;

    }

  })
  
  return (

    <HeroHeaderStyled>

      <LogoWithNavigation />

      <div className="content">

        <div className="content__left-col">

          <h1 className="heading">{data && data.heading || "Heading placeholder text"}</h1>

          <p className="text">{data && data.text || "Description placeholder"}</p>

          <MainCtaButton buttonText="Get Custom-Tailored Content" /> 

        </div>{/* end of .content__left-col */}

        <div className={`content__right-col content__right-col--slider-state--${sliderIndex}`}>

          <div className="hero-content-icon"></div>

          <h2 className="heading">
            {/* <TextTransition
              text={sliderTexts[sliderIndex].title}
              springConfig={presets.gentle}
              noOverflow={true}
            /> */}
            {sliderTexts[sliderIndex].title}
          </h2>

          <p className="text">
            {/* <TextTransition
              text={sliderTexts[sliderIndex].subText}
              springConfig={presets.gentle}
              delay={50}
            /> */}
            {sliderTexts[sliderIndex].subText}
          </p>

          <MainCtaButton buttonText="Let's begin" />

          <SliderNav 
            className="slider-nav"
            arrowColorType={sliderNavArrowColor}
            currentPage={sliderIndex + 1}
            totalPages={sliderTexts.length}
            onPageClick={page => 
              setSliderIndex(page - 1)
            }
          />

        </div>{/* end of .content__right-col */}

      </div>

    </HeroHeaderStyled>
    
    )

}

export default HeroHeader;