import React from "react"

import PageSection from "../../../../../layouts/PageSection/PageSection.component"
import CenterContainer from "../../../../../layouts/containers/CenterContainer/CenterContainer.component"
import { PageComponentPropsI } from "../../../../../components/layout-components/_/PageComponentTypes"
import { hireUs } from "./css/hireUs.css"
import MainCtaBtn from "../../../../../components/layout-components/_/buttons/MainCtaButton/MainCtaButton.component"

interface HireUsPropsI extends PageComponentPropsI {
  //
}


const HireUs: React.FC<HireUsPropsI> = (props) => {

  return (

    <PageSection extendStyles={hireUs} fullWidth={true}>

      <CenterContainer className="hire-us">

        <div className="hire-us__top-part">
          <div className="logo"></div>
          <h2 className="section-title">Hire Us</h2>
        </div>

        <div className="hire-us__content-part">
          <p className="text">If you have a project in mind, you can hire us today! Tell us what you need and we’ll get right back to you with a quote. </p>
          <p className="text">We've done boatloads of articles, blog posts, case studies, you name it. Get in touch and we'll fire up the old typewriter.</p>
        </div>

        <MainCtaBtn buttonText="Hire Us" />

      </CenterContainer>

    </PageSection>

  )

}

export default HireUs;