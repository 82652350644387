import React, { useState, useEffect } from "react"
import styled from "styled-components"

import PageSection from "../../../layouts/PageSection/PageSection.component"
import { PageComponentPropsI } from "../../../components/layout-components/_/PageComponentTypes"
import CenterContainer from "../../../layouts/containers/CenterContainer/CenterContainer.component"
import MainCtaBtn from "../../../components/layout-components/_/buttons/MainCtaButton/MainCtaButton.component"
import SliderNav, { arrowColorType } from "../../../components/layout-components/_/sliders/SliderNav/SliderNav.component"
import { testimonials as testimonialsSectionCss } from "../css/testimonials.css"

const testimonials = [
  {
    title: "Conor Kostick - SnatchBot.me",
    text: "PodroomCreative are a really excellent team and a pleasure to work with. Not only are they professional, prompt and write excellent content, but they are also skilled in SEO and have helped us strategize about where to place our content for maximum impact. I can unreservedly recommend them.",
    caseStudyLink: "/Podroom Creative Guest Blogging Methodology.pdf",
    date: "July 2018 - September 2019"
  },
  {
    title: "Seeking experienced & dynamic creative writers for product descriptions",
    text: "Pavle and his team are second to none when it comes to writing content on Upwork. 1st class, A++ freelancers who take on your initial brief and then get all over it to complete the set task within the agreed deadlines. The product descriptions for our ecommerce shop was completed in a timely manner, and the quality was top notch. Pavle kept us informed all the way thro' and was ever so enthusiastic from the onset to deliver quality work. True professionals, top writers! would highly recommend! We've got more jobs coming soon, so we are not going away for too long. Pavle & his team are the real deal, you will be daft to send your writing needs elsewhere. A************* +++++++++++++++ amazing guys! Thank you!",
    date: "August 2017 - September 2017"
  },
  {
    title: "20 articles for my website",
    text: "If you're looking for high quality, unique and SEO-friendly content at affordable prices, Jovan definitely could help! I have received 30 original articles from him and some of them drive thousands of visitors to my website everyday, that's really amazing! Can't recommend Jovan for writing deep articles enough!",
    date: "January 2019 - September 2020"
  },
  {
    title: "Blog Writing",
    text: "Really pleased with the work Jovan and his team have done for us. He has exceeded expectations in many ways. The work has been submitted ahead of schedule, and the writing requires less editing on part than most other contractors we've worked with. Would recommend Jovan to anyone looking for quality work done on time and on target!",
    date: "January 2019 - September 2020"
  },
  {
    title: "Article Writing",
    text: "Pavle has been an absolute pleasure to work with and the quality of the work is outstanding. We definitely look forward to future projects with him and would highly recommend him and his team. Always on time and always communicative.",
    date: "January 2019 - September 2020"
  }
]

const Testimonials: React.FC<{}> = (props) => {

  const [sliderIndex, setSliderIndex] = useState(0);
  const [changingTextAnimation, setChangingTextAnimation] = useState(false);

  useEffect(() => {

  })

  const _setSliderIndex = (index: number) => {

    setChangingTextAnimation(true);

    // Delay text change because of the animation.
    setTimeout(() => {

      let _index = index;

      if (index > testimonials.length - 1) {
        _index = 0;
      }

      if (index < 0) {
        _index = testimonials.length - 1;
      }

      setSliderIndex(_index);
      
      setChangingTextAnimation(false);

    }, 300);

  }

  return (

    <PageSection extendStyles={testimonialsSectionCss} fullWidth={true}>

      <CenterContainer className="testimonials-container">

        <div className="nav-arrow nav-arrow--left">
          <div className="nav-arrow__icon--wrapper left-wrapper" onClick={_ => _setSliderIndex(sliderIndex - 1)}>
            <div className="nav-arrow__icon nav-arrow__icon--left"></div>
          </div>
        </div>

        <div className={`testimonials ${changingTextAnimation ? 'changing-text-animation' : ''}`}>

          <div className="testimonial active">

            <div className="testimonial__icon"></div>

            <h4 className="testimonial__title">{testimonials[sliderIndex].title}</h4>

            <p 
              className="testimonial__text"
              dangerouslySetInnerHTML={{__html: testimonials[sliderIndex].text}}
            />

            {testimonials[sliderIndex].caseStudyLink && 
              <a 
                className="testimonial__case-study-link"
                href={testimonials[sliderIndex].caseStudyLink}>« Download our success story »</a>
            }

            <div className="testimonial__date">
              <span>{testimonials[sliderIndex].date}</span>
            </div>

          </div>{/* end of .testimonial */}

        </div>{/* end of .testimonials */}

        <div className="nav-arrow nav-arrow--right">
          <div className="nav-arrow__icon--wrapper right-wrapper" onClick={_ => _setSliderIndex(sliderIndex + 1)}>
            <div className="nav-arrow__icon nav-arrow__icon--right"></div>
          </div>
        </div>

      </CenterContainer>

    </PageSection>

  )

}

export default Testimonials;