import { css } from "styled-components"

const leftColWidth = `60%`; 

/**
 * @TODO - Problem passing variables and props to the css`` tagged template, as it breaks the string in array and prints out a comma separated values in css. Either extract values before, or just use 'styled', or somehow parse the tagged literal.
 */

export const whyChooseUs = css`

  section {
    padding: 0;
  }

  /** @TODO - extract this to a component */
  .background-boxes {

    height: 155px;
    display: flex;

    .box {
      background-color: #F9F9F9;
      display: inline-block;
      height: 100%;
    }

    &--1,
    &--2,
    &--3 {
      width: 60%; /*  ${leftColWidth}; @TODO - try using this */
    }

    &--1 {
      
      display: flex;
      justify-content: space-between;

      .box-1 {
        width: 170px;
        border-radius: 0px 0px 40px 0px;

        @media (max-width: 768px) {
          order: 2;
        }
      }
      .box-2 {
        width: 239px;
        border-radius: 0px 0px 0px 40px;

        @media (max-width: 768px) {
          border-radius: initial;
        }
      }
    }

    &--2 {
      .box {
        /* margin-left: 515px; */
        margin-left: 76.5%;
        width: 170px;
        border-radius: 40px 0px 0px 0px;
      }
    }
    &--3 {
      .box {
        width: 236px;
        border-radius: 40px 0px 0px 0px;
      }
      float: left;
    }
    &--4 {
      float: left;
      .box {
        width: 381px;
      }
    }

    @media (max-width: 768px) {
      &--2,
      &--3,
      &--4 {
        display: none;
      }
    }

  }

  .content {

    display: flex;
    
    @media (max-width: 768px) {
      flex-direction: column;
    }

    &__left-col {

      /* flex-basis:  ${leftColWidth}; */
      flex-basis: 60%;
      flex-shrink: 0;

      padding-top: 71px;

      .title {
        margin-top: 0;
        font-weight: normal;
      }

      p.text {
        max-width: 542px;
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 40px;
      }

    }

    &__right-col {

      flex-basis: 460px;
      background-color: black;
      box-sizing: border-box;
      padding: 80px 60px 50px 60px;
      border-radius: 0px 0px 40px 0px;

      @media (max-width: 768px) {
        margin-top: 55px;
        padding: 80px 40px 50px 40px;
      }

      .heading {
        font-family: 'DM Sans', sans-serif;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: white;
        margin-bottom: 20px;
      }

      hr {
        background: #48E4B7 0% 0% no-repeat padding-box;
        border-radius: 0px 5px 5px 0px;
        height: 6px;
        margin: 0;
      }

      .text {
        margin-top: 30px;
        margin-bottom: 65px;
        font-size: 18px;
        color: white;
        line-height: 30px;
        min-height: 240px;

        @media (max-width: 768px) {
          min-height: 300px;
        }
      }

      &__slider-content {
        min-height: 480px;
      }

      .slider-progress {
        height: 6px;
        width: 100%;
        margin-bottom: 20px;
    
        background: #404040;
        position: relative;

        &:after {
          content: " ";
          position: absolute;
          width: 25%;
          background: #48E4B7;
          height: 100%;
          transition: width 300ms;
        }

        /** @TODO - Make this progress transition smoothly - https://keithjgrant.com/posts/2017/07/transitioning-gradients/ */
      }


    }

  } /* end of .content */


`