import { css } from "styled-components"

export const sliderNav = css`

  .hero-slider-nav {

    display: flex;

    &__arrow {
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      vertical-align: middle;
      margin-top: 2px;
      cursor: pointer;
      transition: border-color 300ms;

      &:hover {
        filter: invert(75%);
      }

      &--left {
        border-width: 12px 12px 12px 0;
        margin-right: 18px;
      }
      &--right {
        border-width: 12px 0 12px 12px;
        margin-left: 18px;
      }
      
    }

    &__numbers-container {
      display: inline-block;
      min-width: fit-content;
    }

    &__number {
      cursor: pointer;
      font-size: 24px;
      transition: font-weight 300ms;
      min-height: 24px;

      &:not(:first-child) {
        margin-left: 12px;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: -2px;
        left: 50%;
        transform: translateX(-50%);
        width: 15px;
        border-bottom: 1px solid black;
        opacity: 0;
        transition: opacity 150ms;
      }

      &.active {

        position: relative;

        &:after {
          opacity: 1;
        }

      }
    }



  } 
`
