import React, {useEffect} from "react"

import { MainLayout } from "../../layouts/main.layout"
import HeroHeader from "../../components/layout-components/top/headers/_/hero-header/HeroHeader.component"
import CenterContainer from "../../layouts/containers/CenterContainer/CenterContainer.component"
import FullWidthContainer from "../../layouts/containers/FullWidthContainer/FullWidthContainer.component"
import MainCtaButton from "../../components/layout-components/_/buttons/MainCtaButton/MainCtaButton.component"
import PageSection from "../../layouts/PageSection/PageSection.component"
import { indexPageSection } from "./css/index.css"
import Footer from "../../components/layout-components/bottom/footer/Footer.component"

import HireUs from "../../components/layout-components/_/other/HireUs/HireUs.component"
import { ourOffer } from "./css/ourOffer.css"
import { ourProcess } from "./css/ourProcess.css"
import WhyChooseUs from "./sections/WhyChooseUs.section"
import Testimonials from "./sections/Testimonials.section"
import { getStaticImgUrl } from "../../utils/getStaticImgUrl"
import { getCanonicalUrl } from "../../utils/getCanonicalUrl"

const meta = {
  title: "Let's write your successful ever after",
  description: "Need content for your blog or website that people will actually read? Want to get your audience hooked and grab the attention of search engines? You're in the right place!"
}

const og = {
  image: getStaticImgUrl("Podroom Creative - All in one stop shop for your content.png")
}

const pageSlug = "";

export default () => {
  
  return (

  <MainLayout meta={meta} og={og} canonicalUrl={getCanonicalUrl(pageSlug)}>



    {/* 
    

          Header
    
    */}

    <FullWidthContainer backgroundColor={"#48E4B7"} inHalf={true}>

      <CenterContainer>

        <HeroHeader data={{
          heading: "Let's write your successful ever after", 
          text: "Need content for your blog or website that people will actually read? Want to get your audience hooked and grab the attention of search engines? You're in the right place."
        }}/>

      </CenterContainer>

    </FullWidthContainer>



    {/* 


          "Why choose us"

     */}

    <WhyChooseUs /** css={indexPageSection} **/ />



    {/* 

    
          "Our offer"

    */}

    <PageSection extendStyles={ourOffer} fullWidth={true}>

      <CenterContainer>
    
        <div className="section-title text-center">
          <h2>Our offer</h2>
        </div>

        <div className="services-offer text-center">

          <div className="service-box">
            <div className="left-box-bookmark">
              <div className="left-box-bookmark__icon lbb-icon--1"></div>
            </div>
            <h4 className="heading">Content Writing</h4>
            <div className="service-box-icon orange"></div>
            <p>We offer writing services for anything from short blog listicles and long-form evergreen content to product pages for ecommerce, website copy, landing pages, and even white papers, business case studies, and academic writing.</p>
            <MainCtaButton buttonText="View services" />
          </div>

          <div className="service-box">
          <div className="left-box-bookmark">
              <div className="left-box-bookmark__icon lbb-icon--2"></div>
            </div>
            <h4 className="heading">Guest blogging</h4>
            <div className="service-box-icon maroon"></div>
            <p>We have access to prominent industry - related blogs in several major niches, and we can write compelling content that gets picked up by search engines and read by your audience. Widen your reach and get more visitors with a smart guest blogging strategy.</p>
            <MainCtaButton buttonText="View services" />
          </div>

          <div className="service-box">
            <div className="left-box-bookmark">
              <div className="left-box-bookmark__icon lbb-icon--3"></div>
            </div>
            <h4 className="heading">SMM</h4>
            <div className="service-box-icon cyan"></div>
            <p>Everyone needs some extra brand exposure, the kind you can only get by being active on social media, sharing great content and industry news, and starting conversations with real people looking for specific answers. We can cover this part of your marketing strategy and get people talking about your brand.</p>
            <MainCtaButton buttonText="View services" />
          </div>

        </div>

      </CenterContainer>

    </PageSection>



    {/* 
    
          "Our process"

    */}

    <PageSection extendStyles={ourProcess} fullWidth={true}>

      <CenterContainer className="container">

        <div className="background-boxes">
          <div className="box box--1"></div>
          <div className="box box--2"></div>
        </div>

        <div className="content">

          <h2 className="section-title">Our process</h2>

          <div className="processes">
            
            <div className="processes-row">
              <div className="process">
                <div className="process__icon p-icon--1"></div>
                <h4 className="process__title">Perform<br /> Client Interview</h4>
                <p className="process__description">This is where we read up on your brand, get to know your needs and your target audience, agree on the right style and tone, and determine the project scope.</p>
              </div>
              <div className="process">
                <div className="process__icon p-icon--2"></div>
                <h4 className="process__title">Agree on pricing<br /> and payment options</h4>
                <p className="process__description">Once we know what you need and how much work it’s going to take, we will give you a quote. While we have a fixed price based on content complexity and length, we are open for negotiation if you have a unique request or want us to work on a large project long-term.</p>
              </div>
              <div className="process">
                <div className="process__icon p-icon--3"></div>
                <h4 className="process__title">Assign appropriate writers to task</h4>
                <p className="process__description">Our team is comprised of writers from different walks of life, with a range of unique academic backgrounds, hobbies, and interests. We will find the best fit for your niche, audience, and brand voice, and assign the project to writers who have experience with the top-iconcs you need.</p>
              </div>
            </div>

            <div className="processes-row">
              <div className="process">
                <div className="process__icon p-icon--4"></div>
                <h4 className="process__title">Review<br /> &amp; edit content</h4>
                <p className="process__description">When our writers are finished with their assignments, the content goes to one of our editors, who check it for proper grammar and style and make sure that the client’s instructions have been followed closely.</p>
              </div>
              <div className="process">
                <div className="process__icon p-icon--5"></div>
                <h4 className="process__title">Send content<br /> to client</h4>
                <p className="process__description">Once everything is finished and double-checked, we send you the material, within the agreed-upon deadline, of course.</p>
              </div>
              <div className="process">
                <div className="process__icon p-icon--6"></div>
                <h4 className="process__title">Assess<br /> Feedback</h4>
                <p className="process__description">If you have any other suggestions or additional instructions that could help us fine-tune your content for future orders, we’ll hear you out and make any adjustments going forward. If there are any issues, we will perform the necessary edits to get things just right.</p>
              </div>
            </div>

          </div>

        </div>

      </CenterContainer>

    </PageSection>



    {/* 
    
          "Testimonials"

    */}

    
    <Testimonials />



    {/* 
    
          "Hire us"

    */}

    <HireUs />



    {/* 

          "Footer"

    */}

    <Footer />



  </MainLayout>

)}